import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Download from 'components/download'

export default () => (
  <div>
    <StaticQuery
      query={graphql`
      query FinancialSummary {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              category: {
                regex: "/reports/"
              }
            }
          }
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                date
                title
                file
                featured
              }
            }
          }
        }
      }
    `}
      render={data => (
        <div>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Download
              key={node.id}
              title={node.frontmatter.title}
              date={node.frontmatter.date}
              file={node.frontmatter.file}
              featured={node.frontmatter.featured}
            />
          ))}
        </div>
      )}
    />
  </div>
)